@import "./main.scss";
.wollerData{
    h1{

        @media(max-width: $phone){
          margin-top: 0;
        }
    }
    .card{
        max-width: 1000px;
        margin: 25px auto;
        .ibanInput{
            width: 700px;
            margin: 15px auto;
            display: flex;
            flex-direction: column;
            input{
                width: 100%;
                padding: 15px;
                border: 1px solid rgb(227, 227, 227);
                border-radius: 4px;
                margin-right: auto;
                margin-top: 5px;
                font-size: 25px;
                @media(max-width: $phone){
                    width:42%;
                }
                
            }
            .errorText{
                font-size: 12px;
                color: $kpis_red;
            }
        }
        .buttonYellow{
            margin: 10px auto;
            @media(max-width: $phone){
                width:100%;
                margin: 15px 0 auto;
            }
            &::disabled{
                background-color: $modal-background;
            }
        }
        .imagesContainer{
            display: flex;
            align-items: center;
            flex-direction: column;
            .stripeImage{
                width: 400px;
                @media(max-width: $phone){
                    width:100%;
                }
                
            }
         
        }
        @media(max-width: $phone){
            display: flex;
            color: $black;
            border: $coral-active;
        }

    }
    @media(max-width: $phone){
        padding:25px;
        color: $white;
        background: $black;

    }
}