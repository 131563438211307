@import 'main';

.job-detail{
    width: 100%;
    height: 100vh;
    background-color: $black;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    img{
        width: 60px;
    }

    p{
        font-family: 'HurmeSuperBold';
        font-size: 26px;
        color: $white;
    }

    div{
        background-color: $white;
        border-radius: 20px;
        padding: 20px;
        width: 80%;

        p{
            font-family: 'Hurme';
            font-size: 14px;
            color: $black;
        }

        div{
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: max-content;
            color: $black;
            padding: 0px;
            margin-bottom: 5px;

            img{
                width: 25px;
                height: 25px;
                margin-right: 12px;
                margin-top: -3px;
            }

            p{
                font-family: 'Hurme';
                font-size: 14px;
                font-weight: 700;
                margin: 0 10px 15px 0;
                padding: 1px 2.5px;
                text-align: center;
                color: $black;
            }

            .border{
                border: 2px solid $aqua-jobin-darker;
                border-radius: 5px;
            }

            .blue{
                color: #3543ff;
            }

            .mt{
                margin-top: 2px;
            }
        }

        .title{
            font-family: 'HurmeSuperBold';
            font-size: 16px;
            margin: 0 0 15px;
            color: $black;
        }

    }

    .uppercase{
        text-transform: uppercase;
    }

    .contact{
        font-family: 'Hurme';
        font-size: 16px;
        width: 80%;
        text-align: center;
    }
}