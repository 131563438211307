.response-budget-data-main-title{
    text-transform: uppercase;
    font-family: 'HurmeSuperBold';
    font-size: 20px;
    margin: 10px;
}

.response-budget-data-page{
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.response-budget-data-accept{
    height: 40px;
    width: 85%;
    background-color: #35ffc8;
    font-weight: bold;
    font-size: 16px;
    border-radius: 15px;
    margin: 0px;
    margin-bottom: 20px;
    margin-left: 10px;
    border: 1px solid black;

}

.response-budget-data-negate{
    height: 40px;
    width: 85%;
    background-color: #ff3569;
    font-weight: bold;
    font-size: 16px;
    border-radius: 15px;
    margin: 0px;
    margin-bottom: 20px;
    margin-left: 10px;
    border: 1px solid black;

}

.response-budget-data-no-confirm{
    height: 40px;
    width: 85%;
    background-color: #fae35b;
    font-weight: bold;
    font-size: 16px;
    border-radius: 15px;
    margin: 0px;
    margin-bottom: 20px;
    margin-left: 10px;
    border: 1px solid black;
}

.response-budget-data-price{
    padding: 5px;
    text-align: center;
}

.response-budget-data-price-submit{
    height: 35px;
    width: 100px;
    background-color: #fae35b;
    font-weight: bold;
    font-size: 16px;
    border-radius: 5px;
    margin: 0px;
    margin-bottom: 20px;
    margin-left: 10px;
    border: 1px solid black;
    margin-top: 20px;
}

.response-budget-data-price-omit{
    height: 35px;
    width: 80px;
    background-color: black;
    font-weight: bold;
    font-size: 12px;
    border-radius: 5px;
    margin: 0px;
    margin-bottom: 20px;
    margin-left: 10px;
    border: 1px solid black;
    margin-top: 20px;
    color: white;
}

.f-jc-center{
    display: flex;
    justify-content: center;
}