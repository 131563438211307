@font-face {
    font-family: 'Hurme';
    src: url("../assets/fonts/Hurme.otf");
}

@font-face {
    font-family: 'HurmeSuperBold';
    font-weight: "bold";
    src: url("../assets/fonts/HurmeSuperBold.ttf");
}

$greyish: #b0b0b0;
$coral: #ffe135c8;
$coral-active: #FFE235;
$warm-grey: #848484;
$warm-grey-2: #929292;
$white:#FFF;
$white-two: #e2e2e2;
$white-four: #f0eee9;
$light-grey: #e7e3db;
$black: black;
$modal-background: #000a;
$aqua-wolly: #605bfd;
$aqua-jobin-darker: #3543FF;
$bone-light: #f8f7f6;
$greyish-brown: #444444;
$soft-grey: #f2f2f2;
$black-jobin: #202020;
$kpis_background: #06141d;
$kpis_filter: #1A3B42;
$kpis_red: #FF3569;
$kpis_green: #35FFC8;
$kpis_yelow: #FAE35B;
$blue-wolly-active:#1536ff;
$blue-pay:#5F5BF4;
$blue-pay-disabled:#D1D1FC;

$offset: 187;
$duration: 1.4s;
$tier-margin:25px;
$phone: 640px;
$tablet: 768px;
$desktop: 1024px;


html{
    max-width: 100vw;
    max-height: 100vh;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    line-height: 1.15;
    font-family: "Hurme";
    -webkit-text-size-adjust: 100%;
    body{
        margin: 0;
        #root{
            overflow-y: auto;
            height: 100vh;
        }

    }
    button:disabled{
        background-color: $greyish !important;
    }
    .button{
        padding: 15px 20px;
        width: 25vw;
        border-radius: 15px;
        margin: 10px 0;
        font-size: 20px;
        font-family: 'HurmeSuperBold';
        letter-spacing: 1px;
        cursor: pointer;
        transition: all 0.3s;
        @media(max-width: $phone){
            width: 83vw;
            padding: 10px 0px;
            font-size: 18px;
            margin: 23px 0 0;
        }
    }
    
    .card{
        border: 2px solid $soft-grey;
        padding: 16px 16px 20px;
        border-radius: 10px;
        background-color: $white;
        box-shadow: #0000004d 0px 5px 5px 0px;
        
    }
    .verticalLeftContainer{
        display: flex;
        flex-direction: column;
    }
    .verticalCenterContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
   
    .modalBackground{
        display: flex;
        position: absolute;
        background: $modal-background;
        width: 100vw;
        height: 100vh;
        left: 0;
        z-index: 555;
    
        .modalContainer{
            display: flex;
            background: $white;
            width: 65%;
            margin: auto;
            height: 85%;
            padding: 20px;
            flex-direction: column;
            @media(max-width: $phone){
                width: 85%;
                padding: 10px 20px;
                height: 63%;
            }
            .close{
                width: 100%;
                text-align: right;
            }
            .modalContent{
                min-height: 95%;
                display: flex;
                flex-wrap: wrap;
                @media(max-width: $phone){
                    display: flex;
                    flex-wrap: nowrap;
                    flex-direction: column;
                   
                }
                .modalTitle{
                    width: 100%;
                    @media(max-width: $phone){
                        font-size: 24px;
                        height: 60px;
                       
                    }
                }
            }
        }
    }
    .aquaButton{
        color: $white;
        background-color: $aqua-wolly;
        margin-top: 20px;
    }
    .buttonBlue{
        color: $white;
        background-color: $blue-pay;
        border: transparent;
        font-family: 'HurmeSuperBold';


        &:active{
            background-color: $blue-pay-disabled;
        }
        
    }
    .buttonYellow{
        color: $black;
        background-color: $coral;
        border: transparent;
        font-family: 'HurmeSuperBold';


        &:active{
            background-color: $coral-active;
        }
        
    }
    .footer{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: auto;
        @media(max-width: $phone){
            margin-top: auto;
            margin-bottom: 20px;
            &.dashboardFooter{
                margin-top: 10px;
            }
        }
        
    
        .poweredByImage{
            width: 3%;
            @media(max-width: $phone){
                width: 13%;
            }
        }
        
        .poweredByText{
            font-size: 13px;
            width: 18%;
            
            
            @media(max-width: $phone){
                &.white{
                    width: 75%;
                }
                font-size: 12px;
                width: 65%;
                text-align: left;
                
            }
        }
        &.dashboardFooter{

            margin-top: auto;
            @media(max-width: $phone){
                margin-top: 5px;
            }
        }
    }
    
}


    

