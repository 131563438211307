@import "./main.scss";
.mainContainer{
    position: relative;
}
.upload-photo-page{
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.upload-photo-main-image{
    width: 60px;
}

.upload-photo-main-title{
    text-transform: uppercase;
    font-family: 'HurmeSuperBold';
    font-size: 26px;
}

.upload-photo-main-subtitle{
    text-align: center;
    font-size: 14px;
    width: 300px;
}

.upload-photo-old-date {
    color: darkgrey;
}

.f-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.upload-photo-main-card{
    width: 80%;
    background-color: white;
    border-radius: 20px;
    color: black;
    padding: 20px;
    .f-row.description{
        button{
            margin-left: 0;
            padding-left: 0;
            margin-top: 0;
        }
    }
    .upload-photo-main-card-warning-icon{
        .upload-photo-main-card-p{
            margin-top: 0 !important;
            font-size: 18px;
        }
    }
    .upload-photo-main-card-appointment{
        height: 40px;
        width: 50%;
        background-color: black;
        border-radius: 20px;
        padding: 5px 15px;
        color: white;
        font-size: 15px;
        letter-spacing: 1.5px;
        margin-left: 15px;

    }
    .assistance{
        b{
            text-decoration: underline;
            color: rgb(53, 67, 255);
        }
        
    }
    .datePickerContainer{
        display: flex;
        background: white;
        width: 100vw;
        height: 0;
        overflow: hidden;
        transition: height 0.3s;
        position: absolute;
        align-items: center;
        flex-direction: column;
        left: 0;
        border-radius: 12px;
        bottom: 0;
        top: 10em;
        p{
            font-size: 24px;
            font-family: "HurmeSuperBold";
            margin: 25px 0;
        }
        .SingleDatePicker{
            position: unset;
            .DateInput {
                display: none;
            }
            .SingleDatePicker_picker{
                position: unset;
                .CalendarDay__selected{
                    background: $coral-active;
                    color: $black;
                    border: $black;
                }
            }
        }
        &.active{
      
            height: 80vh;
           
        }
        .buttons.appointmentControls{
            width: 100%;
            display: flex;
            margin: 0 auto;
            justify-content: center;
            align-items: center;
            .cancel-button, .save-button{
                height: 50px;
                width: 40%;
                border-radius: 25px;
                padding: 5px 15px;
                font-size: 15px;
                letter-spacing: 1.5px;
                border: none;
               font-family: "HurmeSuperBold";
               margin-top: 25px;

            }
   
            .cancel-button{
                margin-right: 25px;
            }
            .save-button{
                background-color: $coral-active;
                &:disabled{
                    background-color: $coral !important;
                }
            }
           
        }
    }
}



.upload-photo-main-fixed-price{
    color: rgb(53, 67, 255);
    font-weight: bold;
    margin: 0px;
    margin-right: 10px;
    border: 2px solid rgb(53, 67, 255);
    border-radius: 5px;
    padding: 1px 2.5px;
    text-align: center;
    font-size: 14px;
    margin-bottom: 15px;
}

.upload-photo-main-fixed-gremio{
    text-transform: uppercase;
    color: rgb(53, 67, 255);
    font-weight: bold;
    margin: 0px;
    font-size: 14px;
    margin-bottom: 15px;
}

.upload-photo-main-fixed-job-title{
    font-size: 16px;
    font-family: 'HurmeSuperBold';
    margin: 0px;
    margin-bottom: 15px;
}

.upload-photo-main-card-icon{
    width: 25px;
    height: 25px;
    margin-right: 15px;
}

.upload-photo-main-card-p{
    font-size: 14px;
    margin: 0px;
}

.m-top-20{
    margin-top: 15px;
    font-weight: bold;
}
.m-bott-20{
    margin-bottom: 15px;
}
.m-x-20{
    margin-left: 15px;
    margin-right: 15px;
}
.m-y-20{
    margin-top: 15px;
    margin-bottom: 15px;
}

.upload-photo-main-next{
    background-color: #fae35b;
    padding: 8px 60px;
    border-radius: 50px;
    font-weight: bold;
    font-size: 16px;
    margin-top: 20px;
    font-family: 'HurmeSuperBold';
}

.upload-photos-button{
    height: 50px;
    width: 60%;
    background-color: black;
    color: #fae35b;
    font-weight: bold;
    font-size: 16px;
    border-radius: 10px;
    margin: 0px;
    margin-bottom: 20px;
}

.upload-photos-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90vh;
}

.upload-photos-step{
    color: grey;
    font-weight: bold;
    margin-right: 20px;
    margin-top: 20px;
    margin-left: 30px;
    font-size: 20px;
}

.upload-photos-step-title{
    margin-top: 27px;
    font-weight: bold;
    font-size: 20px;
    font-family: 'HurmeSuperBold';
}

.upload-photos-head-p{
    font-size: 16px;
    margin-top: 20px;
    margin-left: 30px;
    width: 300px;
    margin-top: 0px;
    color: grey;
}

.upload-photos-line-bold{
    border: 2px solid black;
}

.upload-photos-line-thing{
    border: 1px solid grey;
}

.w-50{
    width: 50%;
}

.w-75{
    width: 75%;
}

.w-25{
    width: 25%;
}

.w-100{
    width: 100%;
}

.photoItem{
    width: 49.2%;
    position: relative;
    height: 20vh;
    img{
        width: 100%;
    }
    
   .close{
        position: absolute;
        left: 50%;
        top: 50%;
        color: grey;
        background-color: white;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        transform: translate(-50%, -50%);
        i{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.upload-photos-carrousel{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.photosContainer{
    width: 80%;
    border: 1px solid grey;
    height: 300px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
}

.photosContainer-photo{
    width: 200px;
    height: 200px;
    margin-top: 10px;
}

.upload-photos-carrousel-add{
    margin-bottom: 10px;
    border: 1px solid black;
    padding: 7px;
    border-radius: 10px;    
}

.upload-photos-button-wrapper{
    display: flex;
    width: 100%;
    justify-content: center;
}

.signatureCanvas{
    width: 280px;
    height: 380px;
    border: 1px solid grey;
    border-radius: 10px;
}

.upload-photos-button-clear{
    height: 50px;
    width: 42%;
    background-color: white;
    color: grey;
    font-weight: bold;
    border: none;
    font-size: 16px;
    margin: 0px;
    margin-bottom: 20px;
    margin-right: 10px;
}

.upload-photos-button-signature{
    height: 50px;
    width: 42%;
    background-color: black;
    color: #fae35b;
    font-weight: bold;
    font-size: 16px;
    border-radius: 5px;
    margin: 0px;
    margin-bottom: 20px;
    margin-left: 10px;
}

.upload-photo-wolly-image{
    margin-top: 15px;
    width: 40px;
}

.upload-photos-finish-wrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.upload-photos-finish-main-image{
    height: 120px;
}

.upload-photo-finish-wolly-image{
    height: 100px;
}

.upload-photos-finish-title{
    font-weight: bold;
    font-size: 24px;
}

.m-top-120{
    margin-top: 120px;
}

.upload-photo-modal-title{
    font-family: 'HurmeSuperBold';
}
.upload-photo-modal-wrapper{
    margin-top: 20px;
    flex: 1;
    overflow-y: auto;
    height: calc(100vh - 260px);
    flex-direction: column;
}

.upload-photo-main-card-description{
    border: none;
    background-color: white;
    color: rgb(53, 67, 255);
    text-decoration: underline;
    margin-top: -10px;
    margin-left: 30px;
}

.customModal {
    background: #b2dbbf;
    max-width: 500px;
    width: 100%;
  }

.upload-photos-price-total{
    font-family: 'HurmeSuperBold';
    font-size: 24px;
    max-width: 290px;
    margin-bottom: 0px;
    margin-top: 0px;
}

.upload-photos-price-semi{
    font-family: 'Hurme';
    font-size: 16px;
    max-width: 250px;
}

.upload-photos-price-add{
    font-family: 'HurmeSuperBold';
    margin-top: 50px;
}

.upload-photos-price-input{
    padding: 5px;
    width: 140px;
    text-align: center;
    margin: 2px;
}

.upload-photos-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
}

.upload-photos-price-button{
    width: 100px;
    padding: 8px;
    background-color: black;
    font-family: 'Hurme';
    color: white;
    border-radius: 20px;
}

.ml-20{
    margin-left: 20px;
}

.ml-25{
    margin-left: 34px;
}

.upload-photo-modal-price-title{
    font-family: 'HurmeSuperBold';
    font-size: 20px;
}

.upload-photos-signature-price{
    width: 300px;
    margin: 0px;
    margin-bottom: 20px;
}