@import "./main.scss";
.finish{
    background-color: $white;
    width: 100vw;
    position: relative;
    display: flex;
    flex-direction: column;
    color: $black;
    align-items: center;
    text-align: center;
    .titleContainer{
        .title{
            font-size: 40px;
               
        }
    }
    .infoContainer{
        p{
            max-width: 670px;
            @media(max-width: $phone){
                max-width: 600px;

            }
        }
        img{
            width: 25px;
        }
    }
    @media(max-width: $phone){
        padding: 25px;
        width: unset;
    }

}